.it-header-slim-wrapper {
  background-color: #636d88;
}

.it-header-wrapper.it-header-wrapper
  .it-header-center-wrapper.it-header-center-wrapper {
  background-color: white;

  .it-brand-wrapper {
    svg,
    img {
      width: auto;
      height: 70px;

      @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')-1px) {
        margin-left: 20px;
      }
    }

    figure {
      margin: 0 1em 0 0;

      &.icon {
        width: auto;
        max-width: 200px;
      }
    }
  }

  .it-brand-text {
    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #3a3a3a;
    }

    h3 {
      text-transform: uppercase;
    }

    body:not(.subsite) & {
      @extend .visually-hidden;
    }
  }

  .it-header-center-content-wrapper {
    .it-brand-wrapper a,
    .it-right-zone,
    .it-right-zone .it-socials ul .icon {
      color: #3a3a3a;
      fill: #3a3a3a;
    }

    .it-search-wrapper a.rounded-icon {
      background: #3a3a3a;

      svg {
        fill: white;
      }

      &:focus,
      &:hover {
        background: darken(#3a3a3a, 10%);
      }
    }
  }
  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    .it-brand-wrapper {
      svg,
      img {
        height: 95px;
      }
    }
  }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
  .it-header-navbar-wrapper {
    background-color: #414b5f;
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: 'lg') - 1px) {
  button.custom-navbar-toggler svg.icon {
    fill: $primary !important;
  }
}

.it-header-navbar-wrapper {
  .navbar .navbar-collapsable .menu-wrapper .it-brand-wrapper {
    svg,
    img {
      width: auto;
      height: 60px;
    }
  }
}

.it-footer-main {
  background-color: #3a3a3a;

  .it-brand-wrapper {
    svg {
      height: 75px;
      width: auto;
    }

    figure {
      margin: 0px;
    }
  }
}

.cookieConsent a {
  color: white;
}

.custom-imola-category-top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .head-tags {
    margin-bottom: 0 !important;
    .data {
      text-transform: none !important;
    }
  }
}
