.public-ui {
  @import 'custom/main';
  @import 'custom/arLogin';
  //import here your site customization styles
}
@import 'custom/site/cms';

.contenttype-document {
  .title-description-wrapper {
    display: flex;
    flex-direction: column;
    .it-carousel-all.it-card-bg.page-header-gallery {
      overflow: visible;
      flex: 1;
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        display: none;
      }
      .slick-list {
        margin: 0 -0.2rem;
      }
      .slick-slider {
        width: 100%;
      }
      .slick-slide {
        margin: 0 0.2rem;
      }
      .slick-track {
        display: flex;
        flex-wrap: nowrap;
      }
      .volto-image.responsive img {
        object-fit: cover;
        object-position: center;
        aspect-ratio: 16/9 !important;
      }
    }
    &.col-lg-6 .it-carousel-all.it-card-bg.page-header-gallery {
      min-width: 120%;
    }
    &.col-lg-12 .it-carousel-all.it-card-bg.page-header-gallery {
      min-width: 60%;
      max-width: 60%;
      position: relative;
      top: unset;
    }
  }
}
